<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-card flat>
                Kiosk Update
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "kioskUpdates"
}
</script>